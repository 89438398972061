
import { defineComponent } from 'vue'
import AdministracionInterfaz from '@/views/Administracion/AdministracionInterfaz.vue'
import IniciarSesion from '@/views/IniciarSesion.vue'
import { estadoUsuario } from '@/usuario'

export default defineComponent({
  name: 'AdministracionInicializado',
	data() {
    return {
			estadoUsuario: estadoUsuario
		}
  },
  components: {
		AdministracionInterfaz,
		IniciarSesion
  }
});
