
import { defineComponent } from 'vue'
import { usuario, cerrarSesión } from '@/usuario'
import { cuenta } from '@/cuenta'

export default defineComponent({
  name: 'AdministracionInterfaz',
  data: () => ({
		usuario: usuario,
		cuenta: cuenta
	}),
	methods: {
		cerrarSesión: function() {
			cerrarSesión()
		}
	}
});
