<template>
	<AdministracionInicializado v-if="estadoUsuario.inicializado"></AdministracionInicializado>
	<CargaInicial v-else></CargaInicial>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import AdministracionInicializado from '@/views/Administracion/AdministracionInicializado.vue'
import CargaInicial from '@/views/CargaInicial.vue'
import { estadoUsuario } from '@/usuario'

export default defineComponent({
  name: 'Administracion',
	data() {
    return {
			estadoUsuario: estadoUsuario
		}
  },
  components: {
		AdministracionInicializado,
    CargaInicial
  }
});
</script>