<template>
	<div class="uk-box-shadow-large" uk-sticky>
		<nav class="uk-navbar-container uk-navbar-transparent uk-section-primary" uk-navbar="mode: click">

			<div class="uk-navbar-left">
				<ul class="uk-navbar-nav">
					<li class="uk-active"><router-link to="/administracion">Inicio</router-link></li>
				</ul>
			</div>

			<div class="uk-navbar-right">
				<div class="uk-inline">
					<router-link to="/administracion/documentacion" class="uk-button uk-button-text uk-margin-left uk-margin-right">Documentación</router-link>
					<router-link to="/administracion/integracion" class="uk-button uk-button-text uk-margin-left uk-margin-right">Integración</router-link>
					<button type="button" class="uk-icon-button uk-margin-top uk-margin-bottom uk-margin-right" style="margin-top: 5px !important; margin-bottom: 5px !important" uk-icon="more-vertical"></button>
					<div class="uk-card uk-card-default" uk-dropdown="mode: click">
						<h3 class="uk-card-title uk-margin-remove-bottom" style="color: unset !important">Hola, {{ usuario.nombres }}</h3>
						<p class="uk-text-meta uk-margin-remove-top" style="color: unset !important">{{ cuenta.empresa.nombreComercial }}</p>
						<hr style="border-top-color: silver">
							<button type="button" class="uk-button uk-button-text" style="color: unset !important" @click="cerrarSesión">Cerrar sesión</button>
					</div>
				</div>
			</div>

		</nav>
	</div>

	<div id="menu-principal" class="uk-visible@m" style="width: 250px; position: fixed; top: 46px; bottom: 0; box-sizing: border-box; border-right: 1px #e5e5e5 solid; overflow: auto; background-color: #434a51;">
		<ul class="uk-nav uk-nav-default">
			<li class="uk-nav-header" style="margin-top: 30px">Registros</li>
			<li><router-link to="/administracion/eventos"><span uk-icon="bell"></span> Eventos</router-link></li>
			<li><router-link to="/administracion/entes"><span uk-icon="user"></span> Entes</router-link></li>
			<li><router-link to="/administracion/grupos-de-entes"><span uk-icon="users"></span> Grupos de entes</router-link></li>
			<li class="uk-nav-header">Configuraciones</li>
			<li><router-link to="/administracion/reglas"><span uk-icon="file-text"></span> Reglas</router-link></li>
			<li><router-link to="/administracion/verificacion"><span uk-icon="refresh"></span> Verificación</router-link></li>
		</ul>
	</div>

	<main style="padding: 60px 60px 60px 310px">

		<router-view></router-view>

	</main>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { usuario, cerrarSesión } from '@/usuario'
import { cuenta } from '@/cuenta'

export default defineComponent({
  name: 'AdministracionInterfaz',
  data: () => ({
		usuario: usuario,
		cuenta: cuenta
	}),
	methods: {
		cerrarSesión: function() {
			cerrarSesión()
		}
	}
});
</script>

<style scoped>

ul.uk-nav-default {
	font-weight: 500;
}

#menu-principal > .uk-nav-default > li > a, #menu-principal > .uk-nav-default > li.uk-nav-header {
	padding: 15px 30px;
}

#menu-principal > .uk-nav-default > li > a {
	color: #ccc;
}

#menu-principal > .uk-nav-default > li {
	color: #aaa;
}

#menu-principal > .uk-nav-default > li > a:hover {
	background-color: black;
	color: white;
}

#menu-principal > .uk-nav-default > li > a > .uk-icon {
	margin-right: 5px;
}

</style>