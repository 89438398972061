<template>
	<div class="uk-container">
		<div class="uk-position-center">

			<p class="uk-text-center uk-text-large uk-text-primary">verifico</p>

			<form class="uk-card uk-card-default uk-card-body uk-box-shadow-xlarge">

				<div class="uk-margin">
					<label class="uk-form-label" for="iniciar-sesion-correo">Correo electrónico</label>
					<div class="uk-form-controls">
						<div class="uk-inline">
							<span class="uk-form-icon" uk-icon="icon: user"></span>
							<input id="iniciar-sesion-correo" class="uk-input" type="email" v-model="correo" @keypress.enter="acceder">
						</div>
					</div>
				</div>

				<div class="uk-margin">
					<label class="uk-form-label" for="iniciar-sesion-clave">Contraseña</label>
					<div class="uk-form-controls">
						<div class="uk-inline">
							<span class="uk-form-icon uk-form-icon" uk-icon="icon: lock"></span>
							<input id="iniciar-sesion-clave" class="uk-input" type="password" v-model="contraseña" @keypress.enter="acceder">
						</div>
					</div>
				</div>

				<div class="uk-margin">
					<button type="button" class="uk-button uk-button-primary uk-width-1-1" :disabled="desactivado" @click="acceder">
						<span v-if="estadoUsuario.autenticando">Autenticando...</span>
						<span v-else>Iniciar sesión</span>
					</button>
				</div>

			</form>

			<p class="uk-text-center"><router-link to="/">Ir a la página de inicio</router-link></p>

		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { estadoUsuario, iniciarSesión } from '@/usuario'

export default defineComponent({
  name: 'IniciarSesion',
  data: () => ({
		estadoUsuario: estadoUsuario,
		correo: '',
		contraseña: '',
	}),
	computed: {
		desactivado: function (): boolean {
			return !this.correo || !this.contraseña || estadoUsuario.autenticando
		},
	},
	methods: {
		acceder: function() {
			iniciarSesión(this.correo, this.contraseña)
		}
	}
});
</script>
