
import { defineComponent } from 'vue'
import { estadoUsuario, iniciarSesión } from '@/usuario'

export default defineComponent({
  name: 'IniciarSesion',
  data: () => ({
		estadoUsuario: estadoUsuario,
		correo: '',
		contraseña: '',
	}),
	computed: {
		desactivado: function (): boolean {
			return !this.correo || !this.contraseña || estadoUsuario.autenticando
		},
	},
	methods: {
		acceder: function() {
			iniciarSesión(this.correo, this.contraseña)
		}
	}
});
